import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { addSubcategories, addSubcategoriesInitValues as initialValues } from '../../../modules/accounts/components/settings/SettingsModel'
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom'
import { add_subcategory } from '../api/subcategoriesApi';
import { get_all_active_categories, get_all_categories } from '../../categories/api/categoriesApi';
import { toast } from 'react-toastify';
          
const subcategorySchema = Yup.object().shape({
  categoryTitle: Yup.string().required("Category is required"),
  sTitle: Yup.string().required("Subcategory Title is required"),
})
interface Category {
  id: number;
  title: string;
}
function AddSubcategories() {
  const [categoryTitle, setCategoryTitle] = useState<Category[]>([]);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { data } = useQuery('get_all_active_categories', get_all_active_categories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (data) {
      setCategoryTitle(data.data as Category[]);
    }
  }, [data]);
  const mutation = useMutation(add_subcategory, {
    onSettled: (data) => {
      toast.success(data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      navigate('/categories/subcategories-list')
      setLoading(false)
      queryClient.invalidateQueries('subcategories');
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });

  const formik = useFormik<addSubcategories>({
    initialValues,
    validationSchema: subcategorySchema,
    onSubmit: (values) => {
      setLoading(true)
      mutation.mutate({
        "categories_id": values.categoryTitle,
        "title": values.sTitle,
        "status": values.status,
      });
    },
  }
  )
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Subcategory</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Category</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('categoryTitle')}
                  value={formik.values.categoryTitle} onChange={formik.handleChange} onBlur={formik.handleBlur}
                >
                  <option value=''>Select a Type...</option>
                  {Array.isArray(categoryTitle) && categoryTitle.length > 0 ? (
                    categoryTitle.map((CategoryType) => (
                      <option key={CategoryType.id} value={CategoryType.id}>
                        {CategoryType.title}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No categories available</option>
                  )}

                </select>
                {formik.touched.categoryTitle && formik.errors.categoryTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.categoryTitle}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Subcategory Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder="Subcategory Title"
                  {...formik.getFieldProps('sTitle')}
                />
                {formik.touched.sTitle && formik.errors.sTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sTitle}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Status</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('status')}
                >
                  <option value='1'>Active</option>
                  <option value='0'>Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button data-toggle="tooltip" data-placement="bottom" title="Add Subcategory" type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Add Subcategory'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div >
    </div >
  )
}

export default AddSubcategories
