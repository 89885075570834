import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {get_all_users_filter, get_single_user, get_tenant_setting, update_tenant_user, update_user } from '../api/usersApi';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { filter_role_by_status, get_all_roles } from '../roles/api/rolesApi';
import { validateImage } from '../../../helper-functions/ImageValidation';
import secureLocalStorage from 'react-secure-storage';
type User = {
  id: number;
  title: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  lastname: string | undefined;
  telephone: string | undefined;
  address: string | undefined;
  email: string | undefined;
  password: string | undefined;
  mobile: string | undefined;
  picture: string | undefined;
  role: string | undefined;
  password_confirmation: string | undefined;
  status: number | undefined;
  created_at: string;
  deleted_at: string;
  updated_at: string;
};
interface userroleList {
  id: number;
  name: string;
};
function EditUser() {
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [userPicture, setUserPicture] = useState<File | null>(null);
  const [roleList, setRoleList] = useState<userroleList[]>([]);
  const [firstNameError, setFirstNameError] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [telephoneValidationError, setTelephoneValidationError] = useState('');
  const [mobileValidationError, setMobileValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const id = (location.state as { id: number }).id;
  const { data: viewUser, isLoading: viewIsLoading, error: viewIsError, refetch } = useQuery(
    ['get_single_user', id], 
    () => get_single_user(id),
    {
      staleTime: 0, 
      cacheTime: 0, 
      refetchOnWindowFocus: true,
    }
  );
  
  useEffect(() => {
    refetch(); // Jab bhi component render ho, API call forcefully ho jaye
  }, [id, refetch]);
  
  useEffect(() => {
    if (viewUser) {
      setSelectedUser(viewUser.data[0]);
    }
  }, [viewUser]);  
    const { data:roleData } = useQuery(
      'filter_role_by_status', 
      () => filter_role_by_status(1),
      { refetchOnWindowFocus: false }
    );
  const [organisationApplicationData, setOrganisationApplicationData] = useState<{
    application_id: number;
    organization_id: number;
  }>({
    application_id: 0,
    organization_id: 0,
  });
  useEffect(() => {
    if (roleData) {
      setRoleList(roleData.data);
    }
  }, [roleData]);
  useEffect(() => {
    const fetchTenantSetting = async () => {
      try {
        const result = await get_tenant_setting();
        setOrganisationApplicationData(result);
      } catch (error) {
        console.error('Error fetching tenant settings:', error);
      }
    };

    fetchTenantSetting();
  }, []);
  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newFirstName = e.target.value;
      if (!newFirstName) {
        setFirstNameError('FirstName is required.');
      } else {
        setFirstNameError('');
      };
      setSelectedUser({
        ...selectedUser,
        firstname: newFirstName,
      });
    };
  };
  const handleTitleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedUser) {
      const newTitleName = e.target.value;
      if (!newTitleName) {
        setTitleError('Title is required.');
      } else {
        setTitleError('');
      };
      setSelectedUser({
        ...selectedUser,
        title: newTitleName,
      });
    };
  };
  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const addressNew = e.target.value;
      setSelectedUser({
        ...selectedUser,
        address:addressNew,
      });
    };
  };
  const handleMidddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const middleName = e.target.value;
      setSelectedUser({
        ...selectedUser,
        middlename: middleName,
      });
    };
  };
  const handlEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const email = e.target.value;
      if (!email) {
        setEmailError('Email is required.');
      } else {
        setEmailError('');
      };
      setSelectedUser({
        ...selectedUser,
        email: email,
      });
    };
  };
  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const lastName = e.target.value;
      setSelectedUser({
        ...selectedUser,
        lastname: lastName,
      });
    };
  };
  const handleTelephoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newUserTelephone = e.target.value;
      setSelectedUser({
        ...selectedUser,
        telephone: newUserTelephone,
      });
      if (newUserTelephone) {
        const numberRegex = /^[+0-9]+$/;
        if (!numberRegex.test(newUserTelephone)) {
          setTelephoneValidationError('Telephone Number must be in numeric value.');
        } else {
          setTelephoneValidationError('');
        };
      };
    }
  };
  const handleUserRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedUser) {
      const newRoleName = e.target.value;
      if (!newRoleName) {
        setRoleError('Role is required.');
      } else {
        setRoleError('');
      };
      setSelectedUser({
        ...selectedUser,
        role: newRoleName,
      });
    };
  };
  const handleUserMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      const newUserMobile = e.target.value.trim();
      setSelectedUser({
        ...selectedUser,
        mobile: newUserMobile,
      });
      if (!newUserMobile) {
        setMobileValidationError('Mobile Number is required.');
      } else {
        const numberRegex = /^[+0-9]+$/;
        if (!numberRegex.test(newUserMobile)) {
          setMobileValidationError('Mobile Number must be in numeric value.');
        } else {
          setMobileValidationError('');
        };
      };
    };
  };
  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    logoType: string,
    MAX_FILE_SIZE: number,
    ALLOWED_TYPES: string[],
    MAX_DIMENSIONS: { [key: string]: { width: number; height: number } }
  ) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      // Call the validation function
      validateImage(
        newFile,
        logoType,
        (validFileUrl) => {
          // If valid, update the state with the image URL
          if (logoType === 'picture') {
            setUserPicture(newFile);
          }
        },
        MAX_FILE_SIZE, // File size limit in MB
        ALLOWED_TYPES, // Allowed file types
        MAX_DIMENSIONS // Maximum dimensions per logo type
      );
    }
  };
  const handleStatusChange = (e: any) => {
    if (selectedUser) {
      const status = e.target.value;
      setSelectedUser({
        ...selectedUser,
        status: status,
      });
    }
  };
  const handleUpdateUser = () => {
    try {
      setLoading(true);
      if (!selectedUser) {
        throw new Error('No User Selected for Update');
      }
      // Extract `frontend_domain` from window location
      const currentURL = window.location.hostname; // 'abc.com' will be extracted
      const frontendDomain = currentURL.replace(/^www\./, ''); // Remove 'www.' if it exists

      // Get `backend_domain` from secureLocalStorage and remove `http://` or `https://`
      const backendDomain = (typeof secureLocalStorage.getItem('baseURL') === 'string'
        ? secureLocalStorage.getItem('baseURL') as string
        : ''
      )?.replace(/^(https?:\/\/)/, '');

      // Get `organization_id` and `application_id` from organisationApplicationData state
      const { organization_id, application_id } = organisationApplicationData;
      // Create data for `add_tenant_user` API
      const {
        id,
        title,
        firstname,
        middlename,
        lastname,
        telephone,
        email,
        password,
        mobile,
        role,
        address,
        password_confirmation,
        status
      } = selectedUser;
      const tenantFormData: any = new FormData();
      // User form data
      const formData: any = new FormData();
      formData.append('id', id);
      formData.append('title', title || '');
      formData.append('firstname', firstname || '');
      formData.append('middlename', middlename || '');
      formData.append('address', address || '');
      formData.append('lastname', lastname || '');
      formData.append('telephone', telephone || '');
      formData.append('email', email || '');
      formData.append('password', password || '');
      formData.append('mobile', mobile || '');
      formData.append('status', status);
      if (userPicture) {
        formData.append('picture', userPicture);
      }
      formData.append('role', role || '');
      formData.append('password_confirmation', password_confirmation || '');
      // Tenant user Form data
      tenantFormData.append('first_name', firstname || '');
      tenantFormData.append('middle_name', middlename || '');
      tenantFormData.append('last_name', lastname || '');
      tenantFormData.append('email', email || '');
      tenantFormData.append('mobile_no', mobile || '');
      tenantFormData.append('organization_id', organization_id || '');
      tenantFormData.append('application_id', application_id || '');
      tenantFormData.append('frontend_domain', frontendDomain || '');
      tenantFormData.append('domain', backendDomain || '');
      if (userPicture) {
        tenantFormData.append('profile_picture', userPicture);
      }

      // End Tenant user form data
      updateTenantUserMutation.mutate({
        tenantFormData
      });
      updateUserMutation.mutate({
        id,
        formData,
        tenantFormData
      });
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
        });
      }
      setLoading(false);
    }
  };
  const updateUserMutation = useMutation((Data: any) => update_user(Data.id, Data.formData), {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
      navigate('/users/users-list');
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      ref.current && (ref.current.value = '')
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  const updateTenantUserMutation = useMutation((Data: any) => update_tenant_user(Data.tenantFormData), {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  if (viewIsLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
  if (viewIsError) {
    return (
      <div className='text-center'>
        <h2>Error in Get Single Users</h2>
      </div>
    );
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit User</span>
        </h3>
        <div
          className='card-toolbar'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List of UsersED"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/users/users-list')}
          >
            List of Users
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedUser ? (
            <>
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Email</span>
                </label>
                <div className='col-lg-8 fv-row'>
                <label className='form-control form-control-lg form-control-solid user-input'>
                   {selectedUser.email}
                </label>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Role</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser.role || ''}
                    onChange={(e) => {
                      handleUserRoleChange(e)
                    }}
                  >
                    <option value=''>Select a Role...</option>
                    {Array.isArray(roleList) && roleList.length > 0 ? (
                      roleList.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No roles available</option>
                    )}
                  </select>
                  {roleError && (
                    <div className='text-danger'>{roleError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Title</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser.title || ''}
                    onChange={(e) => handleTitleChange(e)}
                  >
                    <option value=''>Select a Title...</option>
                    <option value='Mr.'>Mr.</option>
                    <option value='Mrs.'>Mrs.</option>
                    <option value='Ms.'>Ms.</option>
                  </select>
                  {titleError && (
                    <div className='text-danger'>{titleError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  <span>First Name</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='First Name'
                    value={selectedUser.firstname || ''}
                    onChange={(e) => handleFirstNameChange(e)}
                  />
                  {firstNameError && (
                    <div className='text-danger'>{firstNameError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Middle Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Middle Name'
                    value={selectedUser.middlename || ''}
                    onChange={(e) => handleMidddleNameChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Last Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Last Name'
                    value={selectedUser.lastname || ''}
                    onChange={(e) => handleLastNameChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address'
                    value={selectedUser.address || ''}
                    onChange={(e) => handleAddressChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Telephone</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Telephone'
                    value={selectedUser.telephone || ''}
                    onChange={(e) => handleTelephoneChange(e)}
                  />
                  {telephoneValidationError && (
                    <div className='text-danger'>{telephoneValidationError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Mobile number</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Mobile number'
                    value={selectedUser.mobile || ''}
                    onChange={(e) => handleUserMobileChange(e)}
                  />
                  {mobileValidationError && (
                    <div className='text-danger'>{mobileValidationError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e) => handleImageChange(
                      e,
                      'picture',
                      2, // Max file size (2MB)
                      ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                      {
                        picture: { width: 200, height: 200 }
                      } // Max dimensions
                    )}
                    accept="image/jpeg, image/jpg, image/png"
                    name='picture'
                  />
              <div className='text-danger mx-6 mt-2'>
                Recommended size: 200 x 200 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
              </div>
                  {selectedUser.picture && (
                    <div className='mt-2'>
                      <img
                        src={selectedUser.picture}
                        alt='User Picture'
                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Status</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <Form.Select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedUser.status}
                    onChange={(e) => handleStatusChange(e)}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Select>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {firstNameError || lastNameError || titleError || telephoneValidationError || roleError ? (
            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update User" className='btn btn-primary disabled'>
              Update User
            </button>
          ) : (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Update User"
              type='button'
              className='btn btn-primary'
              onClick={handleUpdateUser}
              disabled={loading}
            >
              {!loading && 'Update User'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
};
export default EditUser;