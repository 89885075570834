import axios from "axios";
import { config_multipart } from "../../api-helper/api-header-config";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
const REACT_APP_CENTRAL_API_URL = process.env.REACT_APP_API_URL_CENTRAL;
// Add User API
export const add_user= async (values:object) => {
    let response = await axios.post(baseURL + "/api/user",values);
    return response.data;
  }
  export const add_tenant_user = async (formData:object) => {
    const response = await axios.post(REACT_APP_CENTRAL_API_URL + "/api/add-tenant-user", formData, {
    });
    return response.data;
};
  // Get All User And Filter
  export const get_all_users_filter =async (status) => {
    const response = await axios.post(baseURL + '/api/filter-user/' + status);
      return response.data;
  };
  // Delete User
export const delete_user = async (id: number) => {
  let response = await axios.delete(baseURL + "/api/user/" + id);
  return response.data;
}
// change status 
export const change_active_status_user = async (id:number) => {
  let response = await axios.post(baseURL + "/api/update-status/" + id, {
    model_name:"user",
  });
  return response.data;  
}
// Get All Users Logs
export const get_all_users_logs = async () => {
  let response = await axios.get(baseURL + "/api/user-activity-logs");
  return response.data;
}
// Edit User
export const update_user = async (id:number,formData:object) => {
  const response = await axios.post(baseURL + "/api/update-user/" + id, formData,config_multipart)
return response.data;
}
// Get Single User
export const get_single_user = async (id) => {
  let response = await axios.get(baseURL + "/api/user/"+id);
  return response.data;
}
// Get Single User Logs by ID
export const get_user_logs = async (id: number) => {
  let response = await axios.get(baseURL + "/api/user-activity-log/" + id);
  return response.data;
};
export const user_password_reset = async (token, email, password, password_confirmation) => {
  const response = await axios.post(
    `${baseURL}/api/reset-password`,
    {
      token: token,
      email: email,
      password: password,
      password_confirmation: password_confirmation
    }
  );
  return response.data;
};
export const get_tenant_setting = async () => {
  const response = await axios.get(baseURL + '/api/get-tenant-setting');
    return response.data.data;
};
export const verify_user = async (email) => {
  let response = await axios.post(REACT_APP_CENTRAL_API_URL + "/api/user-exists", {
    'email': email
  });
  return response.data;
}
export const update_tenant_user = async (formData:object) => {
  const response = await axios.post(REACT_APP_CENTRAL_API_URL + "/api/update-tenant-user", formData,config_multipart)
return response.data;
}